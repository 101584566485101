<template>
  <span>
    <v-row dense>
      <v-col cols="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Serviços Pendentes + Serviços Suspensos</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPendentesSuspensosOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>PSA - Percentual de Serviços em Atraso</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartPsaOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row dense>
      <v-col cols="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Serviços Solicitados</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartSolicitadosOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
      <v-col cols="6">
        <v-card class="my-1">
          <v-card-title>
            <h5>Serviços Suspensos</h5>
          </v-card-title>
          <v-card-text>
            <highcharts 
              :options=chartSuspensosOptions 
              :deepCopyOnUpdate="false" 
            />
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>
  </span>
</template>

<script>

export default {
  name: "DashboardServicosVisaoAneelCardsKpisPorCompetencia",
  props: {
    dados: {
      type: Object,
      required: true,
    },
    categories: {
      type: Array,
      required: true
    },
    metasKpis: {
      type: Object,
      required: true
    },
    exportingOptions : {
      type: Object,
      required: true,
    },
  },
  data: () => ({
    chartPendentesSuspensosOptions: {},
    chartPsaOptions: {},
    chartSolicitadosOptions: {},
    chartSuspensosOptions: {},
  }),
  mounted() {
    this.setChartsOptions();
  },
  methods: {
    setChartsOptions() {
      this.setChartPendentesSuspensosOptions();
      this.setChartPsaOptions();
      this.setChartSolicitadosOptions();
      this.setChartSuspensosOptions();
    },
    getColumnChartDefaultOptions() {
      return {
        chart: {
          type: 'column',
          zoomType: 'x',
          height: 300,
        },
        colors: [
          "#550899",
          "#2196F3",
          "#00e272",
          "#fe6a35",
          "#6b8abc"
        ],
        credits: { enabled: false },
        title: { text: null },
        subtitle: { text: null },
        legend: { enabled: true },
        xAxis: {
          categories: this.categories,
          crosshair: true,
          title: { 
            text: "Competência" 
          }
        },
        yAxis: {
          title: { text: null },
        },
        plotOptions: {
          column: {
            stacking: 'normal',
          }
        },
        tooltip: { 
          shared: true,
        },
        lang: {
          noData: "Sem dados para exibição"
        },
        series: [],
        exporting: this.exportingOptions,
      };
    },
    setChartPendentesSuspensosOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = [
        {
          name: "Suspensos",
          animation: false,
          data: this.categories
            .map(category => {
              return category in this.dados?.suspensos ?? {}
                ? { x: this.categories.indexOf(category), y: Number(this.dados.suspensos[category]) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            // .filter(val => val !== null && val.y !== null && val.y > 0)
        },
        {
          name: "Pendentes",
          animation: false,
          data: this.categories
            .map(category => {
              return category in this.dados?.pendentes ?? {}
                ? { x: this.categories.indexOf(category), y: Number(this.dados.pendentes[category]) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            // .filter(val => val !== null && val.y !== null && val.y > 0)
        }
      ];

      this.chartPendentesSuspensosOptions = options;
    },
    setChartPsaOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = [
        {
          name: "PSA",
          animation: false,
          data: this.categories
            .map(category => {
              return category in this.dados?.psa ?? {}
                ? { x: this.categories.indexOf(category), y: Number(this.dados.psa[category]) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            // .filter(val => val !== null && val.y !== null && val.y > 0)
        },
        {
          name: "Meta",
          animation: false,
          stickyTracking: false,
          type: "spline",
          data: this.categories
            .map(category => {
              return category in this.metasKpis
                ? { x: this.categories.indexOf(category), y: Number(this.metasKpis?.[category]?.psa) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            .filter(val => val !== null && val.y !== null)
        }
      ];

      this.chartPsaOptions = options;
    },
    setChartSolicitadosOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = [
        {
          name: "Solicitados",
          animation: false,
          data: this.categories
            .map(category => {
              return category in this.dados?.solicitados ?? {}
                ? { x: this.categories.indexOf(category), y: Number(this.dados.solicitados[category]) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            // .filter(val => val !== null && val.y !== null && val.y > 0)
        },
        {
          name: "Meta",
          animation: false,
          stickyTracking: false,
          type: "spline",
          data: this.categories
            .map(category => {
              return category in this.metasKpis
                ? { x: this.categories.indexOf(category), y: Number(this.metasKpis?.[category]?.solicitacoes) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            .filter(val => val !== null && val.y !== null)
        }
      ];

      this.chartSolicitadosOptions = options;
    },
    setChartSuspensosOptions() {
      let options = this.getColumnChartDefaultOptions();

      options.series = [
        {
          name: "Suspensos",
          animation: false,
          data: this.categories
            .map(category => {
              return category in this.dados?.suspensos ?? {}
                ? { x: this.categories.indexOf(category), y: Number(this.dados?.suspensos[category]) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            // .filter(val => val !== null && val.y !== null && val.y > 0)
        },
        {
          name: "Meta",
          animation: false,
          stickyTracking: false,
          type: "spline",
          data: this.categories
            .map(category => {
              return category in this.metasKpis
                ? { x: this.categories.indexOf(category), y: Number(this.metasKpis?.[category]?.suspensos) } 
                : { x: this.categories.indexOf(category), y: null }
            })
            .filter(val => val !== null && val.y !== null)
        }
      ];

      this.chartSuspensosOptions = options;
    },
  },
  watch: {
    dados() {
      this.setChartsOptions();
    }
  },
}
</script>

<style></style>